import './css/Account.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData as fetchUserDataService } from './services/api';  // Import the API service

function Account({ darkMode }) {
  const [userData, setUserData] = useState(null);  // Start with null to track loading state
  const [isEditing, setIsEditing] = useState(false);
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Only attempt to fetch data if token exists
    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await fetchUserDataService(token);

          if (response.status === 200) {
            const data = response.data;
            setUserData(data);  // Populate user data
          } else {
            console.error('Failed to fetch user data:', response.status);
            navigate('/login');  // Redirect to login if the fetch fails
          }
        } catch (error) {
          console.error('Error fetching user data:', error.message);
          navigate('/login');  // Redirect on error
        }
      };

      fetchUserData();
    } else {
      // If no token, redirect to login
      navigate('/login');
    }
  }, [navigate]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    setPasswords({ password: '', confirmPassword: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUserData({ ...userData, profile_pic: URL.createObjectURL(file) });  // Temporarily show the uploaded image
    }
  };

  const handleSave = async () => {
    if (passwords.password && passwords.password !== passwords.confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    const token = localStorage.getItem('token');
    let profilePicPath = userData.profile_pic;

    if (selectedFile) {
      const formData = new FormData();
      formData.append('profile_pic', selectedFile);

      try {
        const response = await fetch('/api/users/me/profile-pic', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          profilePicPath = `${data.profile_pic}`;
          console.log('Profile picture uploaded:', data);
        } else {
          console.error('Failed to upload profile picture:', response.status);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      }
    }

    const updatedData = {
      ...userData,
      profile_pic: profilePicPath, // Use updated or existing profile pic
    };

    try {
      const response = await fetch('/api/users/me', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        console.log('User data updated:', data);
        setIsEditing(false);
      } else {
        console.error('Failed to update user data:', response.status);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  // If userData is still null (loading) or undefined, show nothing or a loading indicator
  if (!userData) {
    return <p>Loading...</p>;
  }

  return (
    <div className={`account-container mt-5 ${darkMode ? 'dark-mode' : ''}`}>
      <h1>Account Information</h1>
      {isEditing ? (
        <div className="account-edit-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={userData.name}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="profile_pic">Profile Picture</label>
            <input type="file" id="profile_pic" onChange={handleFileChange} />
            {userData.profile_pic && (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}${userData.profile_pic}`}
                alt="Profile"
                className="profile-pic"
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="bio">Bio</label>
            <textarea
              id="bio"
              name="bio"
              value={userData.bio}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={passwords.password}
              onChange={handlePasswordChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handlePasswordChange}
            />
          </div>

          <button onClick={handleSave} className="btn btn-save">
            Save Changes
          </button>
          <button onClick={handleEditToggle} className="btn btn-cancel">
            Cancel
          </button>
        </div>
      ) : (
        <div className="account-details">
          {userData.profile_pic && (
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}${userData.profile_pic}`}
              alt="Profile"
              className="profile-pic"
            />
          )}
          <p><strong>Name:</strong> {userData.name}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Bio:</strong> {userData.bio}</p>
          <button onClick={handleEditToggle} className="btn btn-edit">
            Edit
          </button>
        </div>
      )}
    </div>
  );
}

export default Account;
