import React, { useState } from 'react';
import axios from 'axios';
import './CurrencyConverter.css';

const CurrencyConverter = () => {
  // State variables
  const [baseCurrency, setBaseCurrency] = useState('');
  const [targetCurrency, setTargetCurrency] = useState('');
  const [amount, setAmount] = useState(1);
  const [conversionRate, setConversionRate] = useState(null);
  const [conversionHistory, setConversionHistory] = useState([]);
  const [error, setError] = useState('');

  // List of popular fiat currencies and cryptocurrencies
  const popularCurrencies = [
    // Major Fiat Currencies
    { id: 'usd', name: 'US Dollar (USD)' },
    { id: 'eur', name: 'Euro (EUR)' },
    { id: 'gbp', name: 'British Pound (GBP)' },
    { id: 'jpy', name: 'Japanese Yen (JPY)' },
    { id: 'aud', name: 'Australian Dollar (AUD)' },
    { id: 'cad', name: 'Canadian Dollar (CAD)' },
    { id: 'chf', name: 'Swiss Franc (CHF)' },
    { id: 'cny', name: 'Chinese Yuan (CNY)' },
    { id: 'inr', name: 'Indian Rupee (INR)' },
    { id: 'rub', name: 'Russian Ruble (RUB)' },
    { id: 'brl', name: 'Brazilian Real (BRL)' },
    { id: 'mxn', name: 'Mexican Peso (MXN)' },
    { id: 'zar', name: 'South African Rand (ZAR)' },
    { id: 'nzd', name: 'New Zealand Dollar (NZD)' },
    { id: 'krw', name: 'South Korean Won (KRW)' },
    { id: 'sek', name: 'Swedish Krona (SEK)' },
    { id: 'sgd', name: 'Singapore Dollar (SGD)' },
    { id: 'nok', name: 'Norwegian Krone (NOK)' },
    { id: 'pln', name: 'Polish Zloty (PLN)' },
    { id: 'try', name: 'Turkish Lira (TRY)' },
    { id: 'thb', name: 'Thai Baht (THB)' },
    { id: 'dkk', name: 'Danish Krone (DKK)' },
    { id: 'myr', name: 'Malaysian Ringgit (MYR)' },
    { id: 'php', name: 'Philippine Peso (PHP)' },
    { id: 'hkd', name: 'Hong Kong Dollar (HKD)' },
    { id: 'twd', name: 'New Taiwan Dollar (TWD)' },
    { id: 'vnd', name: 'Vietnamese Dong (VND)' },
    { id: 'czk', name: 'Czech Koruna (CZK)' },
    { id: 'huf', name: 'Hungarian Forint (HUF)' },
    { id: 'ils', name: 'Israeli Shekel (ILS)' },
    { id: 'clp', name: 'Chilean Peso (CLP)' },
    { id: 'ars', name: 'Argentine Peso (ARS)' },
  
    // Popular Cryptocurrencies
    { id: 'btc', name: 'Bitcoin (BTC)' },
    { id: 'eth', name: 'Ethereum (ETH)' },
    { id: 'bnb', name: 'Binance Coin (BNB)' },
    { id: 'usdt', name: 'Tether (USDT)' },
    { id: 'xrp', name: 'XRP (XRP)' },
    { id: 'doge', name: 'Dogecoin (DOGE)' },
    { id: 'ada', name: 'Cardano (ADA)' },
    { id: 'sol', name: 'Solana (SOL)' },
    { id: 'matic', name: 'Polygon (MATIC)' },
    { id: 'ltc', name: 'Litecoin (LTC)' },
    { id: 'dot', name: 'Polkadot (DOT)' },
    { id: 'shib', name: 'Shiba Inu (SHIB)' },
    { id: 'avax', name: 'Avalanche (AVAX)' },
    { id: 'bch', name: 'Bitcoin Cash (BCH)' },
    { id: 'trx', name: 'TRON (TRX)' },
    { id: 'atom', name: 'Cosmos (ATOM)' },
    { id: 'link', name: 'Chainlink (LINK)' },
    { id: 'xlm', name: 'Stellar (XLM)' },
    { id: 'ftt', name: 'FTX Token (FTT)' },
    { id: 'leo', name: 'UNUS SED LEO (LEO)' },
    { id: 'algo', name: 'Algorand (ALGO)' },
    { id: 'etc', name: 'Ethereum Classic (ETC)' },
    { id: 'vet', name: 'VeChain (VET)' },
    { id: 'icp', name: 'Internet Computer (ICP)' },
    { id: 'hbar', name: 'Hedera (HBAR)' },
    { id: 'mana', name: 'Decentraland (MANA)' },
    { id: 'sand', name: 'The Sandbox (SAND)' },
    { id: 'axs', name: 'Axie Infinity (AXS)' },
    { id: 'aave', name: 'Aave (AAVE)' },
    { id: 'zec', name: 'Zcash (ZEC)' },
    { id: 'fil', name: 'Filecoin (FIL)' },
    { id: 'sushi', name: 'SushiSwap (SUSHI)' },
    { id: 'yfi', name: 'yearn.finance (YFI)' },
    { id: 'bat', name: 'Basic Attention Token (BAT)' },
    { id: 'luna', name: 'Terra (LUNA)' },
    { id: 'near', name: 'NEAR Protocol (NEAR)' },
    { id: 'gala', name: 'Gala (GALA)' },
  ];
  

  // Handle conversion between base and target currency
  const handleConvert = async () => {
    if (!baseCurrency || !targetCurrency) {
      setError('Please select both base and target currencies');
      return;
    }

    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${baseCurrency}&vs_currencies=${targetCurrency}`
      );

      const rate = response.data[baseCurrency]?.[targetCurrency];
      if (!rate) {
        setError(`Conversion between ${baseCurrency} and ${targetCurrency} is not available.`);
        return;
      }

      setConversionRate(rate);
      setConversionHistory([{ base: baseCurrency, target: targetCurrency, amount, rate }, ...conversionHistory]);
    } catch (error) {
      setError('Error fetching conversion rate.');
      console.error('Error fetching conversion rate:', error);
    }
  };

  // Clear error when selections change
  const clearError = () => setError('');

  return (
    <div className="currency-converter-container">
      <h2>Currency Converter</h2>

      {/* Base Currency Dropdown */}
      <div className="currency-dropdown">
        <label htmlFor="base-currency">Base Currency</label>
        <select
          id="base-currency"
          value={baseCurrency}
          onChange={(e) => {
            setBaseCurrency(e.target.value);
            clearError();
          }}
        >
          <option value="">Select Base Currency</option>
          {popularCurrencies.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.name}
            </option>
          ))}
        </select>
      </div>

      {/* Target Currency Dropdown */}
      <div className="currency-dropdown">
        <label htmlFor="target-currency">Target Currency</label>
        <select
          id="target-currency"
          value={targetCurrency}
          onChange={(e) => {
            setTargetCurrency(e.target.value);
            clearError();
          }}
        >
          <option value="">Select Target Currency</option>
          {popularCurrencies.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.name}
            </option>
          ))}
        </select>
      </div>

      {/* Amount Input */}
      <label htmlFor="amount">Amount</label>
      <input
        type="number"
        id="amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />

      {/* Convert Button */}
      <button onClick={handleConvert}>Convert</button>

      {/* Conversion Result */}
      {conversionRate && (
        <div className="conversion-result">
          <h3>
            {amount} {baseCurrency.toUpperCase()} = {(amount * conversionRate).toFixed(2)} {targetCurrency.toUpperCase()}
          </h3>
        </div>
      )}

      {/* Error Display */}
      {error && <div className="error-message">{error}</div>}

      {/* Conversion History */}
      {conversionHistory.length > 0 && (
        <div className="conversion-history">
          <h4>Conversion History</h4>
          <ul>
            {conversionHistory.map((history, index) => (
              <li key={index}>
                {history.amount} {history.base.toUpperCase()} = {(history.amount * history.rate).toFixed(2)} {history.target.toUpperCase()}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CurrencyConverter;
