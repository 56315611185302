import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from './services/api';
import './css/SignUp.css';

function SignUp({ darkMode }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    nameInvalid: false,
    emailInvalid: false,
    passwordMismatch: false,
    invalidPassword: false,
  });

  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, password, confirmPassword } = formData;

    // Reset errors
    setErrors({
      nameInvalid: false,
      emailInvalid: false,
      passwordMismatch: false,
      invalidPassword: false,
    });

    if (name.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, nameInvalid: true }));
      return;
    }

    if (!validateEmail(email)) {
      setErrors((prevErrors) => ({ ...prevErrors, emailInvalid: true }));
      return;
    }

    if (password !== confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, passwordMismatch: true }));
      return;
    }

    if (!validatePassword(password)) {
      setErrors((prevErrors) => ({ ...prevErrors, invalidPassword: true }));
      return;
    }

    try {
      // Use the sign-up API service
      const response = await signUp(name, email, password);

      if (response.status === 201) {
        setSuccessMessage('Sign-up successful! Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      console.error('Error during sign-up:', error.response?.data?.message || error.message);
      if (error.response?.status === 400) {
        alert('User already exists or invalid data.');
      } else {
        alert('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <div className={`sign-up-container mt-5 ${darkMode ? 'dark-mode' : ''}`}>
      <h2 className="text-center mb-4">Create Your Account</h2>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
        <div className="form-group mb-3">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="form-control"
            placeholder="Enter your full name"
          />
          {errors.nameInvalid && <small className="text-danger">Name cannot be empty.</small>}
        </div>

        <div className="form-group mb-3">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-control"
            placeholder="Enter your email"
          />
          {errors.emailInvalid && <small className="text-danger">Please enter a valid email address.</small>}
        </div>

        <div className="form-group mb-3">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className="form-control"
            placeholder="Create a password"
          />
          {errors.invalidPassword && (
            <small className="text-danger">
              Password must contain at least 8 characters, including a capital letter, a number, and a symbol.
            </small>
          )}
        </div>

        <div className="form-group mb-4">
          <label>Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className="form-control"
            placeholder="Confirm your password"
          />
          {errors.passwordMismatch && <small className="text-danger">Passwords do not match.</small>}
        </div>

        <button type="submit" className="btn btn-primary w-100">
          Sign Up
        </button>
      </form>
    </div>
  );
}

export default SignUp;
