// services/api.js
import axios from 'axios';

// Create an Axios instance with the base URL from the environment
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Use environment variable for the base URL
  withCredentials: true, // Include credentials like cookies (if necessary)
});

// Sign-up function
export const signUp = (name, email, password) => {
  return api.post('/api/signup', {
    name,
    email,
    password,
  });
};

// Login function
export const login = (email, password) => {
  return api.post('/api/login', {
    email,
    password,
  });
};

// Fetch user data
export const fetchUserData = (token) => {
    return api.get('/api/users/me', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
  };